import { Routes, Route } from "react-router-dom";
import AbilitiesPage from "./AbilitiesPage";
import AbilitiesDetailPage from "./AbilitiesPage/AbilitiesDetailPage";
import ITPage from "./ITPage";
import ITDetailsPage from "./ITPage/DetailsPage";
import BusinessNeedsPage from "./BusinessNeedsPage";
import BusinessNeedsDetailPage from "./BusinessNeedsPage/BusinessNeedsDetailPage";
import CoopPage from "./CoopPage";
import OrganizationInfoPage from "./OrganizationInfoPage";
import CompareNav from "./CompareNav";
import "./styles.scss";

const ComparePage = () => {
  return (
    <div className="compare-pages">
      <div className="container">
        <div className="row">
          <div className="col">
            <CompareNav />

            <Routes>
              <Route
                path={"it-prerequisites"}
                element={<ITPage questions="base" />}
              />
              <Route
                path={"it-additional"}
                element={<ITPage questions="additional" />}
              />
              <Route path={"business-needs"} element={<BusinessNeedsPage />} />
              <Route
                path={"business-needs/details/:group/:id"}
                element={<BusinessNeedsDetailPage />}
              />
              <Route
                path={"business-needs/coops/:group/:id"}
                element={<CoopPage />}
              />
              <Route
                path={"abilities/coops/:group/:id"}
                element={<CoopPage />}
              />
              <Route path={"abilities"} element={<AbilitiesPage />} />
              <Route
                path={"abilities/details/:group/:id"}
                element={<AbilitiesDetailPage />}
              />
              <Route
                path={"it-prerequisites/it-details/:qid"}
                element={<ITDetailsPage />}
              />
              <Route
                path={"it-additional/it-details/:qid"}
                element={<ITDetailsPage />}
              />
              <Route
                path={"organization-info/:oid"}
                element={<OrganizationInfoPage />}
              />
            </Routes>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ComparePage;
