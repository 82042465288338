import { useParams } from "react-router-dom";
import { Container } from "react-bootstrap";
import useOrganizationResponse from "services/useOrganizationResponse";
import useRadar from "services/useRadar";
import RadarChart from "components/RadarChart";
import "./style.scss";
import BackButton from "components/BackButton";
import { getSurveyCompletionDate } from "utils/response";

export default () => {
  const { organizationId, oid } = useParams();
  const survey = useOrganizationResponse(organizationId, oid);
  const [radars] = useRadar(oid);

  console.log(organizationId, oid);
  const questions = [
    {
      id: "qRealEstateSystem",
      label: "Fastighetssystem",
    },
    {
      id: "qFastAPI",
      label: "Fast API",
    },
    {
      id: "qInfrastructure",
      label: "Typer av infrastruktur",
    },
    {
      id: "qOperatingSystems",
      label: "Operativsystem",
    },
    {
      id: "qContainers",
      label: "Container-förmåga",
    },
    {
      id: "qIntegrationPlatforms",
      label: "Integrationsplattformar",
    },
    // {
    //   id: "qInfrastructureSatisfaction",
    //   chart: "bar",
    //   domain: [1, 2, 3, 4, 5],
    // },
    {
      id: "qITMaintenance",
      label: "Förvaltning",
    },
    {
      id: "qITRoles",
      label: "Roller",
      multipleValues: true,
    },
    {
      id: "qDevelopmentWoW",
      label: "Arbetssätt",
    },
    {
      id: "qAddingNewServices",
      label: "Nya tjänster",
    },
    {
      id: "qSecurityCompetence",
      label: "Säkerhetskompetens",
    },
    {
      id: "qDigitizing",
      label: "Digitaliseringsresan",
    },
    {
      id: "qCooperationDevelopment",
      label: "Samverkan",
      domain: ["Inte alls", "Sällan", "Vanligt", "Vet ej"],
    },
    {
      id: "qCooperationDevelopmentTypes",
      label: "Samverkan runt utveckling",
    },
  ];

  const formatAnswer = (response, other) => {
    if (Array.isArray(response)) {
      const index = response.indexOf("other");
      if (index !== -1) {
        response[index] = "Annat" + (other ? " - " + other : "");
      }
      return response.join(", ");
    }
    return response === "other" ? "Annat - " + other : response;
  };

  return (
    survey &&
    survey.response &&
    radars.length > 0 && (
      <Container className="organizationInfoPage">
        <BackButton />
        <h2 className="mt-4 mb-0">{survey.response.oname}</h2>
        <ul>
          {questions.map((question) => (
            <li className="d-flex" key={question.id}>
              <div className="label">{question.label}:</div>
              <div>
                {formatAnswer(
                  survey.response[question.id],
                  survey.response[question.id + "-Comment"]
                )}
              </div>
            </li>
          ))}
          <li className="d-flex">
            <div className="label">Genomförd kartläggning:</div>
            <div>{getSurveyCompletionDate(survey)}</div>
          </li>
        </ul>

        <h2 className="mt-4 mb-0">Digitaliseringsradar</h2>

        <div className="radar">
          <RadarChart radarData={radars[0]} />
        </div>
      </Container>
    )
  );
};
