import useStats from "services/useStats";
import StatsDashboard from "./StatsDashboard";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import Tippy from "@tippyjs/react";
import useText from "services/useText";
import HTMLText from "components/HTMLText";

const ITPage = ({ questions }) => {
  const text = useText();
  const { organizationId } = useParams();
  const stats = useStats(organizationId);
  const title =
    questions === "base"
      ? text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.TITLE
      : text.JÄMFÖR_IT_ÖVRIGT.TITLE;

  return (
    <Container className="StatsPage">
      <Row>
        <h1>{title}</h1>
        <HTMLText className="desc">
          {text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.INTRO}
        </HTMLText>
        {/* <p>{text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.INTRO}</p> */}
        <div className="d-flex justify-content-end tooltip-row">
          <Tippy content={text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.INFO}>
            <img src="/info-large.svg" />
          </Tippy>
        </div>
        {stats && (
          <StatsDashboard
            stats={stats}
            organizationId={organizationId}
            questions={questions}
          />
        )}
      </Row>
    </Container>
  );
};

export default ITPage;
