import { Col, Container, Row } from "react-bootstrap";
import "./styles.scss";

const About = () => {
  return (
    <div className="about route-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="mb-5">
            <h1>Bakgrund till Digitaliseringsradarn</h1>
            <p className="paragraph-big">
              För att hjälpa bostadsbolag att få koll på sin digitaliseringsresa
              och underlätta styrning har Sveriges Allmännytta i samverkan med
              100 allmännyttiga bolag tagit fram Digitaliseringsradarn – ett
              effektivt verktyg där du enkelt kartlägger digitaliseringsarbetet
              i bolaget.
            </p>
            <p className="paragraph-big">
              Verktyget ger en översikt av nuläge, samverkansmöjligheter inom
              allmännyttan och även möjligheter att jämföra sig med andra i
              branschen.
            </p>
            <h2>Syftet med Digitaliseringsradarn är följande</h2>
            <ul>
              <li>
                Kontinuerligt mäta och följa upp verksamhetens digitalisering
              </li>
              <li>
                Skapa förståelse och samsyn inom bolaget kring
                digitaliseringsresan
              </li>
              <li>Underlätta strategiarbetet</li>
              <li>Jämför resultatet med andra allmännyttiga bolag</li>
              <li>Rekommendationer kring tänkbara samverkansmöjligheter</li>
            </ul>
            <h2>Hur verktyget är uppbyggt</h2>
            <p>
              Den första versionen av Digitaliseringsradarn lanserades till 100
              medlemsbolag i april 2022. Bostadsbolagen delger information om
              sina förutsättningar gällande teknik och organisation tillsammans
              med sina färdplaner.
            </p>
            <p>
              Verktyget utgår från principen att en färdplan för hållbar
              digitalisering kan delas upp ett antal lager. Det översta utgår
              från verksamhetens behov om att förbättra specifika processer. För
              att stötta detta och göra det genomförbart så behöver ett antal
              digitala förmågor finnas på plats i lagret under. De digitala
              förmågorna stöttas i sin tur av generella tekniska förmågor i det
              nedersta lagret.
            </p>
            <p>
              I den första versionen av verktyget har fokus legat på
              bostadsbolagens förutsättningar för utveckling och förvaltning av
              det existerande fastighetsbeståndet med fokus på bostadsbeståndet,
              nybyggnation och lokalförvaltning har exkluderats.
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default About;
