import { useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import ModalImage from "react-modal-image";
import "./style.scss";

const Feedback = ({ radar, survey, organization, indicators }) => {
  const [abilities, setAbilities] = useState([]);
  const [needs, setNeeds] = useState([]);
  const [abilitiesVsNeedsRatio, setAbilitiesVsNeedsRatio] = useState(null);
  const [
    generalAbilitiesVsAbilitiesRatio,
    setGeneralAbilitiesVsAbilitiesRatio,
  ] = useState(null);
  const [realEstateSystem, setRealEstateSystem] = useState("");
  const [size, setSize] = useState(0);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    console.log("RADAR:", radar);
    setAbilities(
      radar.layers.abilities.filter((a) => a.state == 3 || a.state == 4)
    );
    setNeeds(radar.layers.needs.filter((n) => n.state == 3 || n.state == 4));
    setAbilitiesVsNeedsRatio(
      radar.layers.abilities.filter((a) => a.state == 1 || a.state == 2)
        .length /
        radar.layers.needs.filter((n) => n.state == 3 || n.state == 4).length
    );
    setGeneralAbilitiesVsAbilitiesRatio(
      radar.layers.generalAbilities.length / radar.layers.abilities.length
    );
  }, [radar]);

  useEffect(() => {
    if (survey) {
      if (survey.response.qRealEstateSystem === "other") {
        setRealEstateSystem("något annat fastighetssystem än de vanligaste");
      } else {
        setRealEstateSystem(survey.response.qRealEstateSystem);
      }
      setRoles(survey.response.qITRoles || []);
    }
  }, [survey]);

  useEffect(() => {
    if (organization) {
      setSize(organization[0].ownedHousingCount);
    }
  }, [organization]);

  const CoopLink = ({ text }) => {
    return (
      <Link
        to={
          "/organizations/" +
          organization[0]._id +
          "/compare/it-prerequisites/it-details/qRealEstateSystem"
        }
      >
        {text}
      </Link>
    );
  };

  const ValueBasedFeedback = () => {
    return (
      <>
        <p>
          Det är mycket värdefullt att använda sig utav en så kallad
          värdebaserad planering när man lägger en plan för sin önskelista.
          Starta med att fundera och definiera vilka typ av värden ni vill uppnå
          med förändring, t.ex. Värde för kund, ekonomiskt värde för er
          organisation, värde för samhället osv. Utvärdera{" "}
          <strong>behoven</strong> i er önskelista, på kort och lång sikt.
        </p>
        <p>
          Utvärdera också vilka digitala förmågor som kommer krävas och hur de
          stöttar dessa behov, samt hur redo ni är att implementera dessa samt
          hur stor insats det kräver. Sammantaget (värde + insats) skapar detta
          bra underlag för att prioritera och lägga upp en strategisk plan för
          införandet av era behov.
        </p>
        <p>
          Nedan ser ni exempel på en enkel prioriteringsmatris (del av material
          från blocket{" "}
          <a
            href={
              "https://www.sverigesallmannytta.se/utbildning/sakerstall-strategisk-och-hallbar-digitalisering/"
            }
          >
            “Värdedriven prioritering i Startpaketet kring
            Digitaliseringsradarn"
          </a>
          ) och ett exempel på en färdplan där man jobbat med ovan beskrivna
          tillvägagångssätt.
        </p>
        <div className="d-flex flex-row">
          <div className="d-flex align-items-center w-50 pr-30">
            <ModalImage small="/prioritering.png" large="/prioritering.png" />
          </div>
          <div className="d-flex align-items-center w-50">
            <ModalImage small="/roadmap.png" large="/roadmap.png" />
          </div>
        </div>
        <div className="d-flex flex-row">
          <p className="fst-italic w-50 pr-30">
            Exempel på en prioriteringsmatris - som kan vara ett stöd till att
            komma fram till vad verksamheten bör fokusera på
          </p>
          <p className="fst-italic w-50">
            Exempel på skiktad färdplan - där verksamhetsbehov hänger samman med
            information, digitala förmågor och tekniska komponenter
          </p>
        </div>
      </>
    );
  };

  const NeedsIntro = () => {
    return (
      <span>
        Ni har <strong>{needs.length} behov</strong> i er önskelista som ni ej
        påbörjat, påbörjat eller inte är nöjda med. Ni har samtidigt{" "}
        <strong>{abilities.length} generella digitala förmågor</strong> i samma
        status.{" "}
      </span>
    );
  };

  const WishlistFeedback = () => {
    if (needs.length <= 10) {
      return (
        <>
          <p>
            <NeedsIntro />
            Det är en rimlig mängd behov att implemenetera förutsatt att de
            stöttas av de digitala förmågorna ni har och planerar att få på
            plats. Se gärna över vilka digitala förmågor ni har på plats och hur
            de stöttar så många verksamhetsbehov som möjligt. Eventuellt saknar
            ni någon digital förmåga som strategiskt skulle vara bra att få på
            plats innan verksamhetsbehoven kan tillgodoses.
          </p>
          <ValueBasedFeedback />
        </>
      );
    } else if (needs.length <= 20) {
      return (
        <>
          <p>
            <NeedsIntro />
            Det får anses som relativt många att implementera på ett par års
            sikt. Ni bör nog titta igenom er planering igen för att få fram en
            mer realistisk plan framåt.
          </p>
          <ValueBasedFeedback />
        </>
      );
    } else {
      return (
        <>
          <p>
            <NeedsIntro />
            Det får anses vara väldigt många behov och ni behöver med stor
            sannolikhet planera om. Skulle det vara så att ni fyllt i många
            behov som ej påbörjade trots att de inte är prioriterade att göras
            inom 2 år så kan det vara en bra idé att markera dessa som ej
            prioriterade.
          </p>
          <ValueBasedFeedback />
        </>
      );
    }
  };

  const RealEstateFeedback = ({ system }) => {
    return (
      <>
        <p>
          Fastighetssystemet är oftast navet i ett bostadbolags IT
          infrastruktur. Det kan dock vara problematiskt att förlita sig på att
          det skall täcka alla de behov som ni som bostadbolag har. Detta kan
          också leda till en inlåsning som kan vara begränsande på er
          digitaliseingsresa. Ett exempel på detta kan vara behovet att förse
          verksamheten med en generisk analys-plattform för data som inte bara
          finns i fastighetssystemet. Det kan därför vara bra att arbeta mot en
          mer öppen plattform där inte all funktion och data finns
          fastighetssystemet. Det är alltså bra att lära känna ert
          Fastighetssystem så att ni kan avgöra om det kan stötta er önskan,
          eller om ni behöver kompletterande verktyg eller plattform.
        </p>
        <div className="w-50">
          <ModalImage small="/dataplattform.png" large="/dataplattform.png" />
          <p className="fst-italic pb-4">
            Ett förenklat exempel på hur integration- och API-förmågor kan
            användas för att samla data från fastighetssystem och andra system i
            en generell analysplattform
          </p>
        </div>
        <p>
          <span>
            Ni använder <strong>{system}</strong>.{" "}
          </span>
          {["Momentum PM4"].includes(system) ? (
            <span>
              Denna version av Momentum behöver uppgraderas eller bytas ut för
              att ni skall komma vidare. Det kan vara en bra idé att ta kontakt
              med andra bolag som sitter/suttit i samma situation som er för att
              utbyta erfarenheter och planer för hur man tar sig ur denna
              situation. <CoopLink text={"Andra bolag med " + system} />
            </span>
          ) : (
            <>
              {["aNoPriority", "aNotStarted"].includes(
                survey.response.qGeneralAbilities.aApiPlatform
              ) ? (
                <span>
                  Genom att etablera en api-baserad arkitektur skulle ni kunna
                  dra nytta av de API:er och integrationsmöjligheter som finns i
                  ert fastighetssystem. Ett bra tankesätt är "Vi ska sträva
                  efter att hämta och skicka information mellan våra IT-system
                  och tjänster på ett strukturerat sätt, fördelaktligen genom
                  att använda oss utav redan existerande metoder, dvs. redan
                  befintliga APIer. Saknas dessa ska vi kravställa det från våra
                  leverantörer".
                </span>
              ) : (
                <span>
                  Ni har etablerat en api-plattform vilket ger er goda
                  möjligheten att uttnyttja de API:er och
                  integrationsmöjligheter som finns i ert fastighetssystem för
                  att bygga en teknisk struktur som passar er och era
                  verksamhetsbehov.
                </span>
              )}
            </>
          )}
        </p>
        <p>
          Det är generellt sett en bra idé att skapa en god relation med er
          leverantör av fastighetssystem och berätta vad ni behöver på kort och
          lång sikt. Det kommmer att hjälpa er i att kontinuerligt stämma av hur
          er färdplan stämmer överrens med leverantörens plan för införande av
          stöttande funktionalitet.
        </p>
      </>
    );
  };

  const CoopFeedback = () => {
    return (
      <>
        <p>
          För att få ytterligare tyngd i era färdplaner kan det vara bra att
          samverka med andra inom Sveriges Allmännytta runt kravställan och
          vision framåt gentemot Fastighetsleverantören. Länken här leder till
          en sida där ni kan se vilka andra bolag inom Allmännyttan som använder
          ert fastighetssystem.{" "}
          <CoopLink text=" Länk till sida med potentiell samverkan" />
        </p>
      </>
    );
  };

  const OrganizationFeedback = () => {
    const getSizeText = (size) => {
      return size > 10000 ? "stort" : size > 1000 ? "medelstort" : "mindre";
    };
    const getProjectModel = () => {
      return survey.response.qProjectManagement === "other"
        ? survey.response["qProjectManagement-Comment"]
        : survey.response.qProjectManagement;
    };
    return (
      <>
        <p>
          Ni är ett <strong>{getSizeText(size)} </strong>bolag med{" "}
          <strong>{size}</strong> lägenheter.{" "}
          {size > 10000 && (
            <span>
              Generellt sett är ni bättre rustade för att ta er an utmaningarna
              på digitaliseringsresan. Det är viktigt att tydliggöra bolagets
              strategiska riktning och fokusområden så att mängden initiativ
              inte blir för många och spretiga. Enhetliga underlag och enhetlig
              uppföljning t.ex. i form av projektkontor eller förändringsforum
              är bra att ha på ptats.
            </span>
          )}
          {size <= 10000 && size >= 1000 && (
            <span>
              Mellanstora bolag har ofta en hel del kompetens mer eller mindre
              rollsatt. Ni behöver fundera på vilka nyckelresurser ni har och
              hur ni i organisationen skall arbeta och hur sammarbete mellan
              personal och inom och mellan projekt skall se ut.
            </span>
          )}
          {size < 1000 && (
            <span>
              De mindre bolagen har ofta mindre resurser internt för att kunna
              genomföra förändringar. Genom att känna till sina kompetenser, vad
              som skapar värde, hur det bör ske samt hur arbete ska bedrivas kan
              även mindre bolag genomföra värdeskapande förändring.
            </span>
          )}
        </p>
        <p>
          {survey.response.qProjecManagement === "none" && (
            <span>
              Ni har angivit att ni <strong>saknar</strong> en projektmodell.
              Att jobba strukturerat enligt en väl definierad projektmodell
              hjälper många bolag att komma framåt.
            </span>
          )}
        </p>
        {/* <p>
          {survey.response.qProjectManagement !== "none" && (
            <span>
              Ni använder <strong>{getProjectModel()}</strong> på ert bolag. 
            </span>
          )}
        </p> */}
        <p>
          <span>
            Ta något i mål (hitta er modell för hur ni tar förändring på ett bra
            sätt i mål) - utan att missa värde för er, värde för era kunder,
            inte skapa digitalt utanförskap, inte sätta er i alltför besvärlig
            sits förvaltningsmässigt. Det är lätt att hamna i en situation där
            det känns väldigt svårt att få överblick och att det är för många
            som pågår parallellt. Ett tips om så är fallet är att försöka att ta
            något i mål och hitta er modell för hur ni tar förändring i mål på
            ett bra sätt och välja ut ett verksamhetsbehov som är prioriterat
            och relativt rimligt att få gjort. Genom att lägga fokus på det och
            försöka fundera på hur ni skall organisera er runt detta kommer
            skapa bättre försättningar när ni tar er an resten av er önskelista.
          </span>
        </p>
        <div className="w-75">
          <ModalImage
            small="/simple-approach.png"
            large="/simple-approach.png"
          />
          <p className="fst-italic pb-4">
            Ett förenklat exempel på hur integration- och API-förmågor kan
            användas för att samla data från fastighetssystem och andra system i
            en generell analysplattform
          </p>
        </div>

        <p>
          <span>
            Ta expertisstöd (kommun, arkitekturstöd, strategisk planering,
            kompetens från Sverige Allmännytta och andra allmännyttiga bolag)
            för att stärka er i de frågor där ni själva inte har egen kompetens.
          </span>
        </p>
      </>
    );
  };

  const CooperationFeedback = () => {
    return (
      <>
        {survey.response.qCooperation === "Inte alls" && (
          <p>
            Ni samverkar <strong>inte alls</strong> med andra bolag inom SvA. Då
            har ni en stor potential att börja dra nytta av erfarenheter och
            kunskap hos andra bolag.
          </p>
        )}
        {survey.response.qCooperation === "Sällan" && (
          <p>
            Ni samverkar <strong>sällan</strong> med andra bolag inom SvA. Det
            är en möjlighet för er att göra kunskapsutbyte med andra bolag.
            Bolagen inom SvA skulle kunna samverka mer i högre grad också
            gällande upphandlingar, tjänster och komptens.
          </p>
        )}
        {survey.response.qCooperation === "Vanligt" && (
          <p>
            Det är <strong>vanligt</strong> att ni samverkar med andra bolag
            inom SvA. Det finns säkert många bolag som är intressearade av att
            höra mer om era erfarenheter. Dela gärna med er om detta .
          </p>
        )}
        <p className="mb-0">
          När man börjar titta närmare på samverkan med andra bolag kan det vara
          bra att utgå från följande:
        </p>
        <ul>
          <li>Bolagens storlek</li>
          <li>Vilket fastighetssystem ni använder</li>
          <li>Behovsönskelistan hos olika bolag</li>
        </ul>
        <p className="mb-0">
          Samverkan kan göras på många olika områden och listan här kanske kan
          hjälpa er att hitta fram till något:
        </p>
        <ul className="mb-0">
          <li>Gemensam kravställan mot tjänsteleverantör</li>
          <li>Gemensam investering</li>
          <li>Dela erfarehheter och kunskap</li>
        </ul>
        <p>Om ni går samman med andra blir ni en starkare beställare.</p>
        <div className="w-75">
          <ModalImage small="/samverkan.png" large="/samverkan.png" />
          <p className="fst-italic">
            Samverkans potentialen mellan bolagen inom Sveriges Allmännytta.
          </p>
        </div>
        <p>
          I digitaliseringsradarn finns en hel sektion där ni kan ta del av
          andra bolags önskelistor och förutsättningar organisatoriskt och
          tekniskt. Följ denna länk för att utforska denna näramare.{" "}
          <CoopLink text="Gå till sektion för potentiell samverkan" />
        </p>
        <p>
          Sveriges Allmännyttta kan hjälpa till att facilitera samverkan men
          glöm inte heller att ni kan delta i de processlab som de anordnas
          t.ex.{" "}
          <a
            href={
              "https://www.sverigesallmannytta.se/utbildning/processlabb-for-digitalisering/"
            }
          >
            Processlabb för digitalisering.
          </a>
        </p>
      </>
    );
  };

  const FeedbackSection = ({ title, eventKey, children }) => {
    return (
      <Accordion.Item eventKey={eventKey}>
        <Accordion.Header>{title}</Accordion.Header>
        <Accordion.Body>{children}</Accordion.Body>
      </Accordion.Item>
    );
  };

  return (
    <>
      {survey && (
        <Accordion className="feedback-component" alwaysOpen={true}>
          <FeedbackSection
            title="1. Er önskelista i förhållande till etablerade förmågor"
            eventKey="0"
          >
            <WishlistFeedback />
          </FeedbackSection>

          <FeedbackSection title="2. Fastighetssystemets påverkan" eventKey="1">
            {survey && (
              <>
                <RealEstateFeedback system={realEstateSystem} />
                <CoopFeedback />
              </>
            )}
          </FeedbackSection>

          <FeedbackSection
            title="3. Organisationens storlek och förutsättningar"
            eventKey="2"
          >
            <OrganizationFeedback />
          </FeedbackSection>

          <FeedbackSection
            title="4. Samverkan mellan bolag inom Sveriges allmännytta"
            eventKey="3"
          >
            <CooperationFeedback />
          </FeedbackSection>
        </Accordion>
      )}
    </>
  );
};

export default Feedback;
