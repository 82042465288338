import useUser from "services/useUser";

const { useNavigate } = require("react-router-dom");

const Steps = () => {
  const user = useUser();
  const navigate = useNavigate();

  const goTo = (evt, page) => {
    evt.preventDefault();
    navigate("./organizations/" + user.organizationId + page);
  };

  const clickMapCoords = [
    0, 150, 84, 0, 250, 0, 335, 150, 250, 300, 84, 300, 0, 150,
  ];

  const getClickMapCoordsOffset = (x, y) => {
    return clickMapCoords.map((c, i) => (i % 2 ? c + y : c + x));
  };

  const clickMapLinks = [
    { link: "/digitizing-group", x: 0, y: 0 },
    { link: "/current-survey/introduction", x: 250, y: 150 },
    { link: "/compare/it-prerequisites", x: 500, y: 0 },
    { link: "/radar", x: 750, y: 150 },
  ];

  return (
    <>
      <img src="/polygons.svg" useMap="#clickmap"></img>
      <map name="clickmap">
        {clickMapLinks.map((link) => (
          <area
            onClick={(e) => goTo(e, link.link)}
            shape="poly"
            coords={getClickMapCoordsOffset(link.x, link.y).join(",")}
            href="#"
          />
        ))}
      </map>
    </>
  );
};

export default Steps;
