import { merge } from "d3";
import * as d3 from "d3";
import { useState } from "react";
import D3Template from "./d3-template";
import "./style.scss";
import { text } from "@fortawesome/fontawesome-svg-core";

const Area = {
  STRATEGIC: "strategic",
  NEEDS: "needs",
  ABILITIES: "abilities",
  GENERAL_ABILITIES: "generalAbilities",
};

let radarData = {};
const circleRadius = 275;
const textColumnWidth = 250;
let width = 800;
let height = 900;
let segmentWidth = 100;
const axisOutside = 30;

const groupBy = (arr, fn) =>
  arr
    .map(typeof fn === "function" ? fn : (val) => val[fn])
    .reduce((acc, val, i) => {
      acc[val] = (acc[val] || []).concat(arr[i]);
      return acc;
    }, {});

const appendCircles = (svgElement) => {
  svgElement
    .selectAll(".radar-circle")
    .data(radarData.states)
    .enter()
    .append("circle")
    .attr("cx", width / 2)
    .attr("cy", height / 2)
    .attr("r", 0)
    .classed("radar-circle", true)
    // .transition()
    // .duration(750)
    .attr("r", (d, i) => {
      return segmentWidth * (i + 1);
    });
};

const appendAxis = (svgElement) => {
  svgElement
    .append("line")
    .attr("x1", width / 2 - circleRadius)
    .attr("y1", height / 2)
    .attr("x2", width / 2 + circleRadius)
    .attr("y2", height / 2)
    .classed("radar-axis", true);
  svgElement
    .append("line")
    .attr("x1", width / 2)
    .attr("y1", height / 2 - circleRadius)
    .attr("x2", width / 2)
    .attr("y2", height / 2 + circleRadius)
    .classed("radar-axis", true);
};

const appendLegend = (svgElement) => {
  const quadrants = [
    "Strategiska förbättringsområden",
    "Versamhetsbehov",
    "Digitala förmågor",
    "Generella tekniska förmågor",
  ];

  const addText = (svgElement, copy, x, y, anchor) => {
    svgElement
      .append("text")
      .attr("x", x)
      .attr("y", y)
      .attr("text-anchor", anchor)
      .text(copy)
      .classed("quadrant", true);
  };
  const addRect = (svgElement, x, y, color) => {
    svgElement
      .append("rect")
      .attr("x", x)
      .attr("y", y)
      .attr("width", 40)
      .attr("height", 40)
      .attr("fill", color);
  };

  addText(svgElement, quadrants[0], 45, 25, "start");
  addRect(svgElement, 0, 0, "rgb(141,141,141)");
  addText(svgElement, quadrants[1], width - 45, 25, "end");
  addRect(svgElement, width - 40, 0, "rgb(245,181,64)");
  addText(svgElement, quadrants[2], width - 45, height - 15, "end");
  addRect(svgElement, width - 40, height - 40, "rgb(62,149,166)");
  addText(svgElement, quadrants[3], 45, height - 15, "start");
  addRect(svgElement, 0, height - 40, "rgb(69,161,137)");
};

const digitizingRadar = (svgElement, data) => {
  radarData = data;
  width = parseInt(svgElement.style("width"), 10);
  height = parseInt(svgElement.style("height"), 10);
  segmentWidth = (height / 2 - axisOutside) / radarData.states.length;

  segmentWidth = circleRadius / radarData.states.length;

  svgElement.selectAll("*").remove();

  var tooltipDiv = d3
    .select(".RadarPage")
    .append("div")
    .attr("class", "dr-tooltip")
    .style("opacity", 0)
    .on("click", () => {
      // tooltipDiv.transition().duration(500).style("opacity", 0);
      tooltipDiv.style("left", "-1000px");
      tooltipDiv.style("top", "-1000px");
    });

  // appendCircles(svgElement);
  // appendAxis(svgElement);
  // appendPositions(svgElement, radarData.layers.needs, 1);
  // appendPositions(svgElement, radarData.layers.strategic, 2);
  // appendPositions(svgElement, radarData.layers.generalAbilities, 3);
  // appendPositions(svgElement, radarData.layers.abilities, 4);
  // appendLegend(svgElement);

  const layerArrays = Object.values(data.layers).map((layer) => {
    return Object.entries(groupBy(layer, "state"));
  });

  const getAngles = (n) => {
    if (n === 0) {
      return [(Math.PI * 3) / 2, Math.PI * 2];
    } else if (n === 1) {
      return [0, Math.PI / 2];
    } else if (n === 2) {
      return [Math.PI / 2, Math.PI];
    } else {
      return [Math.PI, (Math.PI * 3) / 2];
    }
  };

  const segmentColors = [
    [
      { color: "#0F8290", opacity: 1 },
      { color: "#0F8290", opacity: 0.7 },
      { color: "#0F8290", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
    [
      { color: "#955289", opacity: 1 },
      { color: "#955289", opacity: 0.7 },
      { color: "#955289", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
    [
      { color: "#2C6082", opacity: 1 },
      { color: "#2C6082", opacity: 0.7 },
      { color: "#2C6082", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
    [
      { color: "#936826", opacity: 1 },
      { color: "#936826", opacity: 0.7 },
      { color: "#936826", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
  ];

  const segmentColorsHash = {
    strategic: [
      { color: "#0F8290", opacity: 1 },
      { color: "#0F8290", opacity: 0.7 },
      { color: "#0F8290", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
    needs: [
      { color: "#955289", opacity: 1 },
      { color: "#955289", opacity: 0.7 },
      { color: "#955289", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
    abilities: [
      { color: "#2C6082", opacity: 1 },
      { color: "#2C6082", opacity: 0.7 },
      { color: "#2C6082", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
    generalAbilities: [
      { color: "#936826", opacity: 1 },
      { color: "#936826", opacity: 0.7 },
      { color: "#936826", opacity: 0.5 },
      { color: "#E6E6E6", opacity: 1 },
    ],
  };

  const quadrants = [1, 2, 3, 4];
  quadrants.forEach((quadrant) => {
    const arcs = svgElement.selectAll(".arcs").data(layerArrays[quadrant - 1]);

    arcs
      .enter()
      .append("path")
      .attr("transform", "translate(" + width / 2 + "," + height / 2 + ")")
      .attr("d", (d, i) => {
        const stateInt = parseInt(d[0]);
        const [startAngle, endAngle] = getAngles(quadrant - 1);
        return d3
          .arc()
          .innerRadius((stateInt - 1) * segmentWidth)
          .outerRadius(stateInt * segmentWidth)
          .startAngle(startAngle)
          .endAngle(endAngle)();
      })
      .attr("fill-opacity", (d, i) => {
        return segmentColors[quadrant - 1][parseInt(d[0]) - 1].opacity;
      })
      .attr("fill", (d, i) => {
        return segmentColors[quadrant - 1][parseInt(d[0]) - 1].color;
      });

    const archNumberG = arcs
      .enter()
      .append("g")
      .attr("transform", (d, i) => {
        const stateInt = parseInt(d[0]);
        const l = (stateInt - 1) * segmentWidth + segmentWidth / 2;
        let x = Math.sin(Math.PI / 5) * l;

        if (quadrant === 1 || quadrant === 4) {
          x = width / 2 - x;
        } else {
          x = width / 2 + x;
        }
        let y = Math.cos(Math.PI / 5) * l;
        if (quadrant === 1 || quadrant === 2) {
          y = height / 2 - y;
        } else {
          y = height / 2 + y;
        }
        return "translate(" + x + "," + y + " )";
      });

    archNumberG.append("circle").attr("fill", "white").attr("r", 12);

    archNumberG
      .append("text")
      .attr("text-anchor", "middle")
      .attr("alignment-baseline", "middle")
      .attr("font-weight", 700)
      .attr("dy", 2)
      .attr("stroke", segmentColors[quadrant - 1][0].color)
      .attr("fill", segmentColors[quadrant - 1][0].color)
      .text(function (d) {
        return d[1].length;
      });
  });

  appendCircles(svgElement);
  appendAxis(svgElement);

  const ggg = svgElement
    .selectAll(".levelsText")
    .data([...radarData.states].reverse()) // non-destructive
    .enter()
    .append("g")
    .attr("transform", (d, i) => {
      return (
        "translate(" +
        width / 2 +
        "," +
        (height / 2 - i * segmentWidth - segmentWidth / 2) +
        " )"
      );
    })
    .attr("width", 100)
    .attr("height", 20)
    .classed("stateTitle", true);

  ggg
    .append("text")
    .attr("text-anchor", "middle")
    .text((d) => d);

  const layers = Object.values(data.layers);
  const rowHeight = 14;
  const titleHeight = 30;
  const groupPadding = 10;
  const legendXOffset = 50;

  const flattenedLayers = [];
  const areas = [];
  let count = 0;
  Object.entries(data.layers).forEach(([area, groups]) => {
    const grouped = groupBy(groups, "state");
    if (area === "needs" && grouped[4]) {
      if (groups.length > 25) {
        grouped[4] = [{ name: "...", subItems: grouped[4] }];
      }
    }
    const numberOfRows = groups.length;
    const totalHeight = numberOfRows * rowHeight + titleHeight;
    let startY = Math.max(height / 4 - totalHeight / 2, titleHeight);
    if (area === "needs") {
      startY += 18;
    }
    let startX = width / 2 + 4 * segmentWidth + legendXOffset;
    if (area === "generalAbilities" || area === "abilities") {
      startY = startY + height / 2;
    }
    if (area === "strategic" || area === "generalAbilities") {
      startX = width / 2 - circleRadius - legendXOffset - textColumnWidth;
    }
    areas.push({ area: area, x: startX, y: startY - titleHeight });
    Object.entries(grouped).forEach(([state, rows], i) => {
      flattenedLayers.push({
        area: area,
        state: parseInt(state),
        values: rows,
        x: startX,
        h: rows.length * rowHeight,
        y:
          i === 0
            ? startY
            : flattenedLayers[count - 1].y +
              flattenedLayers[count - 1].values.length * rowHeight +
              groupPadding,
      });
      count++;
    });
  });

  const textAlignment = (d) => {
    if (d.area === "strategic" || d.area === "generalAbilities") {
      return "end";
    }
    return "start";
  };

  const textXAdjustment = (d) => {
    if (d.area === "strategic" || d.area === "generalAbilities") {
      return textColumnWidth;
    }
    return 10;
  };

  const textXHeaderAdjustment = (d) => {
    if (d.area === "strategic" || d.area === "generalAbilities") {
      return textColumnWidth + 10;
    }
    return 0;
  };

  const stateGroup = svgElement
    .selectAll(".textGroup")
    .data(flattenedLayers)
    .enter()
    .append("g")
    .attr("transform", (d, i) => {
      return "translate(" + d.x + ", " + d.y + ")";
    });

  stateGroup
    .selectAll("text")
    .data((d) => {
      return d.values.map((v) => ({
        area: d.area,
        name: v.name,
        subItems: v.subItems,
      }));
    })
    .enter()
    .append("text")
    .attr("x", textXAdjustment)
    .attr("y", (d, i) => i * rowHeight)
    .attr("alignment-baseline", "hanging")
    .attr("text-anchor", textAlignment)
    .text((d) => d.name)
    .on("click", function (event, d) {
      if (!d.subItems) {
        return;
      }
      const elemRect = this.getBoundingClientRect();
      const bodyRect = document.body.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      tooltipDiv.transition().duration(200).style("opacity", 1);
      tooltipDiv
        .html(d.subItems.map((item) => item.name).join("<br/>"))
        .style("left", elemRect.left + "px")
        .style("top", offset + "px")
        .style("line-height", "13px");
    })
    // .on("mouseover", function (event, d) {
    //   tooltipDiv.transition().duration(200).style("opacity", 0.9);
    //   tooltipDiv
    //     .html("Hej")
    //     .style("left", event.pageX + "px")
    //     .style("top", event.pageY - 28 + "px");
    // })
    // .on("mouseout", function (d) {
    //   tooltipDiv.transition().duration(500).style("opacity", 0);
    // })
    .classed("text-item", true);

  stateGroup
    .append("rect")
    .attr("width", 5)
    .attr("x", (d, i) => {
      if (d.area === "strategic" || d.area === "generalAbilities") {
        return textColumnWidth + 5;
      }
      return 0;
    })
    .attr("height", (d, i) => {
      return d.values.length * rowHeight;
    })
    .attr("fill", (d, i) => {
      return segmentColorsHash[d.area][d.state - 1].color;
    })
    .attr("fill-opacity", (d, i) => {
      return segmentColorsHash[d.area][d.state - 1].opacity;
    });

  svgElement
    .selectAll(".text-line")
    .data(flattenedLayers)
    .enter()
    .append("line")
    .attr("x1", (d, i) => {
      // connection with segments
      const alfa = Math.atan(
        (height / 2 -
          d.y -
          (d.area === "strategic" || d.area === "needs" ? d.h : 0)) /
          (4 * segmentWidth + legendXOffset)
      );
      const l = d.state * segmentWidth;
      const x = Math.cos(alfa) * l;
      return (
        width / 2 + (d.area === "needs" || d.area === "abilities" ? x : -x)
      );
    })
    .attr("y1", (d, i) => {
      const alfa = Math.atan(
        (height / 2 -
          d.y -
          (d.area === "strategic" || d.area === "needs" ? d.h : 0)) /
          (4 * segmentWidth + legendXOffset)
      );
      const l = d.state * segmentWidth;
      const y = Math.sin(alfa) * l;
      return height / 2 - y;
    })
    .attr("x2", (d, i) => {
      // Connection with area text
      return (
        d.x +
        (d.area === "strategic" || d.area === "generalAbilities"
          ? textColumnWidth + 10
          : 0)
      );
    })
    .attr("y2", (d, i) => {
      return d.y + (d.area === "strategic" || d.area === "needs" ? d.h : 0);
    })
    .attr("stroke-width", 2)
    .attr("stroke", (d, i) => {
      return segmentColorsHash[d.area][d.state - 1].color;
    })
    .attr("stroke-opacity", (d, i) => {
      return segmentColorsHash[d.area][d.state - 1].opacity;
    });

  svgElement
    .selectAll(".areaTitle")
    .data(areas)
    .enter()
    .append("text")
    .attr("x", (d) => d.x + textXHeaderAdjustment(d))
    .attr("y", (d) => d.y)
    .attr("alignment-baseline", "hanging")
    .attr("text-anchor", textAlignment)
    .attr("fill", (d) => {
      return segmentColorsHash[d.area][0].color;
    })
    .text((d) => {
      if (d.area === Area.STRATEGIC) {
        return "verksamhetsområden";
      } else if (d.area === Area.NEEDS) {
        return "förändringsbehov";
      } else if (d.area === Area.ABILITIES) {
        return "Digitala förmågor";
      } else {
        return "Tekniska förmågor";
      }
    })
    .classed("areaTitle", true);

  svgElement
    .selectAll(".areaTitleTopRow")
    .data(areas)
    .enter()
    .append("text")
    .attr("x", (d) => d.x + textXHeaderAdjustment(d))
    .attr("y", (d) => d.y - 22)
    .attr("alignment-baseline", "hanging")
    .attr("text-anchor", textAlignment)
    .attr("fill", (d) => {
      return segmentColorsHash[d.area][0].color;
    })
    .text((d) => {
      if (d.area === Area.STRATEGIC) {
        return "Digitaliserade";
      } else if (d.area === Area.NEEDS) {
        return "Prioriterade";
      }
    })
    .classed("areaTitle", true);
};

export default (props) => {
  return (
    <div>
      <D3Template d3Func={digitizingRadar} data={props.radarData} />
    </div>
  );
};
