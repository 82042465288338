import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";

const NextStep = () => {
  return (
    <div className="next-step route-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={8} className="mb-5">
            <h1>Stöd i arbetet framåt</h1>
            <p className="paragraph-big">
              Digitaliseringsradarn är ett verktyg för att följa upp, prioritera
              och planera nästa steg i digitaliseringsarbetet. Till hjälp i det
              arbetet har ni tillgång till en rad stöd i form av vägledningar,
              kunskapshöjande material och omvärldsbevakning. Relaterat till
              materialet finns också konkreta metodstöd för
              digitaliseringsarbetet och startpaket som sänker hindren för er
              att komma igång oavsett vilka förutsättningar ert bostadsbolag
              har. Allt material är framtaget av Sveriges Allmännytta
              tillsammans med deltagande bostadsbolag.
            </p>
            <h2>Välj start - mobilisera, koordinera eller accelerera?</h2>

            <div className="d-flex">
              <div className="bullet">
                <img src="/bullet-orange.svg"></img>
              </div>
              <div>
                <h3>Mobilisera</h3>
                <p>
                  För bolag som står i startblocken att komma igång med
                  digitalisering är första steget att ta fram en
                  Digitaliseringsstrategi, besluta denna i ledningen samt
                  informera styrelsen.
                  <span className="single-line">
                    <strong>Startpaket:</strong> Digitalisering - varför/därför
                  </span>
                  <span className="single-line">
                    <strong>Metodstöd:</strong> Gör en Digitaliseringsstrategi
                    (workshopserie)
                  </span>
                </p>
              </div>
            </div>

            <div className="d-flex">
              <div className="bullet">
                <img src="/bullet-green.svg"></img>
              </div>
              <div>
                <h3>Koordinera</h3>
                <p>
                  För bolag som har en beslutad och kommunicerad
                  Digitaliseringsstrategi är nästa steg färdplanering och en
                  affärsarkitektur för att organisera och prioritera projekten.
                  <span className="single-line">
                    <strong>Startpaket:</strong> Introduktion till
                    affärsarkitektur
                  </span>
                  <span className="single-line">
                    <strong>Metodstöd:</strong> Designa er affärsarkitektur
                    (workshopserie)
                  </span>
                </p>
              </div>
            </div>

            <div className="d-flex">
              <div className="bullet">
                <img src="/bullet-purple.svg"></img>
              </div>
              <div>
                <h3>Accelerera</h3>
                <p>
                  För bolag som arbetar aktivt med digitalt driven
                  verksamhetsutveckling och har en organisation för det är nästa
                  steg skalning och samverkan vid utveckling. Här finns flera
                  startpaket som är inriktade mot ämnesområden såsom;
                  Ärendehantering och RPA, AI och avancerad dataanalys,
                  Strategiska inköp och agil upphandling, Öppen utveckling och
                  DevOps med flera. Vi rekommenderar även att engagera er i
                  Pilotprogrammet!
                  <span className="single-line">
                    <strong>Metodstöd:</strong> Bygg er målarkitektur &
                    masterdatamodell (projekt), Riskanalys (workshopserie),
                    Behovsdesign (workshopserie)
                  </span>
                </p>
              </div>
            </div>

            <h2>Tänk på:</h2>
            <p>
              Använd Digitaliseringsradarn som ett verktyg för att driva
              digitaliseringen framåt, håll kartläggningen och översikten av
              nuläget aktuell. Det går att gå in när som helst och uppdatera
              informationen. Att nå nyttoeffekter av digitalisering kräver
              uthållighet. Det är viktigt att bolagsledningen ger stöd med
              förändringsledning när det behövs och visar tillit till
              medarbetare och chefer.
            </p>
            <p>
              Att samverkan över organisatoriska gränser är motor för
              digitaliseringsarbete. Förmågan att involvera medarbetare från
              olika delar av verksamheten i projekt är viktig likaså öppenhet
              för samverkan utanför det egna bolaget.
            </p>

            <p className="mt-5">
              <strong>
                Behöver du hjälp med facilitering av workshops, inspiration
                eller coachning?
              </strong>{" "}
              Genom Sveriges Allmännytta kan ni få stöd av professionella
              konsulter och strateger inom digitalisering och IT kontakta{" "}
              <a href="mailto:info@sverigesallmannytta.se">
                info@sverigesallmannytta.se
              </a>
            </p>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default NextStep;
