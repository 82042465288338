import "./style.scss";
import useText from "services/useText";

export default ({ activeStep }) => {
  const text = useText();

  return (
    <ul id="progress">
      {[1, 2, 3, 4, 5, 6].map((step, i) => (
        <li
          className={activeStep > i ? "done" : activeStep === i ? "active" : ""}
        >
          <span>{text.ENKÄT["STEG_" + step]}</span>
        </li>
      ))}
    </ul>
  );
};
