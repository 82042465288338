import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useStats from "services/useStats";
import useText from "services/useText";
import useSurvey from "services/useSurvey";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "react-bootstrap";
import "./style.scss";
import BackButton from "components/BackButton";

const AbilitiesDetailPage = () => {
  const { organizationId, group, id } = useParams();
  const stats = useStats(organizationId);
  const text = useText();
  const survey = useSurvey();
  const [states, setStates] = useState(null);

  const getAbilityTitle = (id) => {
    const matrixes = survey.pages
      .filter((p) => p.name === "Förmågor" || p.name === "Generiska förmågor")
      .reduce((memo, p) => {
        return [...memo, ...p.elements.filter((e) => e.type === "matrix")];
      }, []);

    let title = "";
    matrixes.forEach((matrix) => {
      matrix.rows.forEach((row) => {
        if (id === row.value) {
          title = row.text.sv;
        }
      });
    });
    return title;
  };

  useEffect(() => {
    if (stats) {
      const newStates = {
        aNoPriority: {
          title: "Ej prioriterat",
          organizations: [],
        },
        aNotStarted: { title: "Ej startat", organizations: [] },
        aStarted: { title: "Påbörjat", organizations: [] },
        aUsingImprovements: {
          title: "Använder ej tillfredsställande",
          organizations: [],
        },
        aUsingAsIntended: {
          title: "Använder tillfredsställande",
          organizations: [],
        },
      };
      stats.forEach((stat) => {
        if (stat[group] && stat[group][id] && newStates[stat[group][id]]) {
          newStates[stat[group][id]].organizations.push({
            id: stat.oid,
            name: stat.oname,
          });
        }
      });
      setStates(newStates);
    }
  }, [stats]);

  return (
    states && (
      <Container className="abilitiesDetailPage">
        <Row>
          <Col>
            <BackButton />
            <h2>{getAbilityTitle(id)}</h2>
            <p>{text.BEHOV_DETALJER.INTRO}</p>
            {Object.values(states).map((state) => (
              <div className="state" key={state.name}>
                <h3>{state.title}</h3>
                {state.organizations.map((org) => (
                  <Link key={org.name} to={"../organization-info/" + org.id}>
                    {org.name}
                  </Link>
                ))}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    )
  );
};

export default AbilitiesDetailPage;
