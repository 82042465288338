import { useNavigate } from "react-router-dom";
import "./style.scss";

const BackButton = () => {
  const navigate = useNavigate();

  const click = (evt) => {
    evt.preventDefault();
    navigate(-1);
  };
  return (
    <a className="back" href="#" onClick={(e) => navigate(-1)}>
      {"< Tillbaka"}
    </a>
  );
};

export default BackButton;
