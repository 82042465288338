import { NavItem } from "react-bootstrap";
import { Link } from "react-router-dom";

const Footer = ({ hide, user }) => {
  return (
    <footer
      className={"footer mt-auto p-5 bg-light " + (hide ? "hide" : "")}
      id="footer-nav"
    >
      <nav className="container">
        <div className="row">
          <div className="col-md-8 d-flex">
            <NavItem className="me-5">
              <img src="/logo-orange.png"></img>
            </NavItem>
            <div>
              <div className="d-flex">
                <NavItem className="me-4">
                  <Link to={"/about"}>Om Digitaliseringradarn</Link>
                </NavItem>
                <NavItem>
                  <Link to={"/privacy"}>Behandling av personuppgifter</Link>
                </NavItem>
              </div>
              {user.role === "SYS_ADMIN" && (
                <div className="d-flex mt-5">
                  <NavItem className="me-4">
                    <Link to={"/system-administration"}>
                      Systemadministration
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={"/organization"}>Org. administration</Link>
                  </NavItem>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-4">
            <h3>Behöver ni hjälp med Digitaliseringsradarn</h3>
            <NavItem className="col">
              <a href="mailto:info@sverigesallmannytta.se">
                info@sverigesallmannytta.se
              </a>
            </NavItem>
          </div>
        </div>
      </nav>
    </footer>
  );
};

export default Footer;
