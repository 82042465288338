import http from "utils/http";
import { useState, useEffect } from "react";

function useNeedsSummary(organizationId, questionId) {
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    getNeedsSummary(organizationId, questionId);
  }, []);

  async function getNeedsSummary(organizationId, questionId) {
    const response = await http.get(
      "/api/organizations/" + organizationId + "/responses/needsSummary/"
    );
    setSummary(response.data);
  }

  return summary;
}

export default useNeedsSummary;
