import { useEffect } from "react";
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
import { Container, Nav, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import useText from "services/useText";
import useSurvey from "services/useSurvey";

import Login from "../Login";
import Home from "../Home";
import useUser from "../../services/useUser";
import SystemAdministration from "../SystemAdministration";
import RadarPage from "../RadarPage";
import CurrentSituation from "../CurrentSituation";
import OrganizationAdministration from "../OrganizationAdministration";
import OrganizationSelfAdministration from "../OrganizationSelfAdministration";
import ComparePage from "../ComparePage";
import NextStep from "../NextStep";
import navbarItems from "./components/navbarItems";
import Footer from "./components/footer";
import ReactGA from "react-ga4";
import Privacy from "routes/Privacy";
import About from "routes/About";
import "./styles.scss";
import NotifyUpdate from "components/NotifyUpdate";

const App = () => {
  let user = useUser();
  const text = useText();
  const survey = useSurvey();
  let location = useLocation();

  const logoutUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001/api/logout"
      : "/api/logout";

  const loginUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3000/signin"
      : "/signin";

  function RequireAuth({ children }) {
    return user.role !== "NOTLOGGEDIN" ? children : <Navigate to={"/signin"} />;
  }

  const titles = {
    signin: "Logga in",
    "digitizing-group": "Digitaliseringsteam",
    introduction: "Introduktion kartläggning",
    "current-survey": "Kartläggning",
    completed: "Avslutat kartläggning",
    radar: "Resultat",
    "it-prerequisites": "Jämför förutsättningar IT",
    "business-needs": "Jämför verksamhetsbehov",
    abilities: "Jämför verksamhetsbehov",
    "next-step": "Nästa steg",
    about: "Om Dignitaliseringradarn",
    privacy: "Behandling av personuppgifter",
  };

  useEffect(() => {
    const urlSegments = location.pathname.split("/");
    let title = "Startsida";
    if (urlSegments.length > 0) {
      const lastSegment = urlSegments[urlSegments.length - 1];
      if (titles[lastSegment]) {
        title = titles[lastSegment];
      } else {
        if (location.pathname.indexOf("it-prerequisites/it-details") !== -1) {
          title = "IT detaljer - " + lastSegment;
        } else if (
          location.pathname.indexOf("business-needs/details/") !== -1
        ) {
          title = "Verksamhetsbehov detalj - " + lastSegment;
        } else if (location.pathname.indexOf("abilities/details") !== -1) {
          title = "Förmågor detalj - " + lastSegment;
        } else if (location.pathname.indexOf("organization-info") !== -1) {
          title = "Organsationssida - " + lastSegment;
        } else if (location.pathname.indexOf("coops/") !== -1) {
          title = "Potentiell samverkan - " + lastSegment;
        }
      }
    }
    if (!window.GA_INITIALIZED) {
      ReactGA.initialize([
        {
          trackingId: "G-E92MZ7FKRL",
          gaOptions: {
            send_page_view: false,
            // debug_mode: true,
          },
        },
      ]);
      window.GA_INITIALIZED = true;
    }
    console.log("->", title, location.pathname);
    ReactGA.send({
      hitType: "pageview",
      page: location.pathname,
      title,
    });
  }, [location]);

  return (
    <div
      className="App d-flex flex-column vh-100"
      style={{
        backgroundImage: "url(/background-image.jpg)",
        backgroundSize: "cover",
      }}
    >
      {user && text && survey && (
        <>
          <Navbar
            expand="lg"
            className={
              "py-0 " + (location.pathname === "/signin" ? "hide" : "")
            }
          >
            <Container fluid className="top-nav-bar">
              <LinkContainer to="/">
                <Navbar.Brand aria-label="home" className="py-3">
                  Digitaliseringsradarn
                </Navbar.Brand>
              </LinkContainer>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav" role="menu">
                <Nav className="me-auto">
                  {navbarItems.DigitalizationGroup(
                    user.role,
                    user.organizationId
                  )}
                  {navbarItems.CurrentSituation(user.role, user.organizationId)}
                  {navbarItems.Radar(user.role, user.organizationId)}
                  {navbarItems.Comparison(user.role, user.organizationId)}
                  {navbarItems.NextStep(user.role, user.organizationId)}
                </Nav>
                {navbarItems.LoginLogout(user, loginUrl, logoutUrl)}
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <NotifyUpdate />

          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <div role="main">
                    <Home />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/system-administration"
              element={
                <RequireAuth>
                  <div role="main">
                    <SystemAdministration />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organization"
              element={
                <RequireAuth>
                  <div role="main">
                    <OrganizationAdministration />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/digitizing-group"
              element={
                <RequireAuth>
                  <div role="main">
                    <OrganizationSelfAdministration />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/current-survey/*"
              element={
                <RequireAuth>
                  <div role="main">
                    <CurrentSituation />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/organizations/:organizationId/radar"
              element={
                <RequireAuth>
                  <div role="main">
                    <RadarPage />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/compare/*"
              element={
                <RequireAuth>
                  <div role="main">
                    <ComparePage />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/organizations/:organizationId/next-step"
              element={
                <RequireAuth>
                  <div role="main">
                    <NextStep />
                  </div>
                </RequireAuth>
              }
            />
            <Route
              path="/signin"
              element={
                <div role="main">
                  <Login />
                </div>
              }
            />
            <Route
              path="/about"
              element={
                <div role="main">
                  <About />
                </div>
              }
            />
            <Route
              path="/privacy"
              element={
                <div role="main">
                  <Privacy />
                </div>
              }
            />
          </Routes>
          <Footer hide={location.pathname === "/signin"} user={user} />
        </>
      )}
    </div>
  );
};

export default App;
