import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import useSurvey from "services/useSurvey";
import useStats from "services/useStats";
import { Link } from "react-router-dom";
import "./style.scss";
import BackButton from "components/BackButton";

const CoopPage = () => {
  const { organizationId, group, id } = useParams();
  const stats = useStats(organizationId);
  const survey = useSurvey();
  const [states, setStates] = useState(null);

  const qId2Title = {
    qAbilities: "Förmågor",
    qGeneralAbilities: "Generiska förmågor",
    qInformationDataControl: "Generiska förmågor",
  };

  const getTitle = (group, id) => {
    if (group.indexOf("qNeeds") !== -1) {
      const improvementsPage = survey.pages.find(
        (p) => p.name === "Strategiska förbättringsområden"
      );
      const panels = improvementsPage.elements.filter(
        (e) => e.type === "panel"
      );
      const matrixes = panels.map((p) => {
        return p.elements.find((e) => e.type === "matrix");
      });
      let title = "";
      matrixes.forEach((matrix) => {
        matrix.rows.forEach((row) => {
          if (id === row.value) {
            title = row.text.sv;
          }
        });
      });
      return title;
    } else {
      const pageName = qId2Title[group];
      const page = survey.pages.find((p) => p.name === pageName);
      const matrixes = page.elements.filter((e) => e.type === "matrix");
      let nameMap = {};
      matrixes.forEach((matrix) => {
        matrix.rows.reduce((memo, row) => {
          memo[row.value] = row.text.sv;
          return memo;
        }, nameMap);
      });
      return nameMap[id];
    }
  };

  useEffect(() => {
    if (stats) {
      const newStates = {
        aNoPriority: {
          title: "Ej prioriterat",
          organizations: [],
        },
        aNotStarted: { title: "Ej startat", organizations: [] },
        aStarted: { title: "Påbörjat", organizations: [] },
        aUsingImprovements: {
          title: "Använder ej tillfredsställande",
          organizations: [],
        },
        aUsingAsIntended: {
          title: "Använder tillfredsställande",
          organizations: [],
        },
      };
      stats.forEach((stat) => {
        if (stat[group] && stat[group][id]) {
          newStates[stat[group][id]].organizations.push({
            id: stat.oid,
            name: stat.oname,
          });
        }
      });
      setStates(newStates);
    }
  }, [stats]);

  return (
    states && (
      <div className="container businessNeedsDetailPage">
        <div className="row">
          <div className="col">
            <BackButton />
            <h2>Potentiell samverkan runt {getTitle(group, id)}</h2>
            {states.aNotStarted.organizations.map((org) => (
              <Link key={org.name} to={"../organization-info/" + org.id}>
                {org.name}
              </Link>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default CoopPage;
