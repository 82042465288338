import { NavLink } from "react-router-dom";
import { Button, Nav, Navbar } from "react-bootstrap";
import ConditionalNavLink from "./conditionalNavLink";
import Tippy from "@tippyjs/react";

const DigitalizationGroup = (role, orgId) => {
  const roleConditional = role === "ORG_ADMIN" || role === "SYS_ADMIN";
  const to = "/organizations/" + orgId + "/digitizing-group";
  const text = "Team";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={"aaaaa"}
    />
  );
};

const CurrentSituation = (role, orgId) => {
  const roleConditional =
    role === "EDITOR" || role === "ORG_ADMIN" || role === "SYS_ADMIN";
  const to = "/organizations/" + orgId + "/current-survey/introduction";
  const text = "Kartläggning";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={"current-survey"}
    />
  );
};

const Radar = (role, orgId) => {
  const roleConditional = role !== "NOTLOGGEDIN";
  const to = "/organizations/" + orgId + "/radar";
  const text = "Radarbild";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={"radar"}
    />
  );
};

const Comparison = (role, orgId) => {
  const roleConditional = role !== "NOTLOGGEDIN";
  const to = "/organizations/" + orgId + "/compare/it-prerequisites";
  const text = "Jämför bolag";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={"compare"}
    />
  );
};

const NextStep = (role, orgId) => {
  const roleConditional = role !== "NOTLOGGEDIN";
  const to = "/organizations/" + orgId + "/next-step";
  const text = "Nästa steg";

  return (
    <ConditionalNavLink
      roleConditional={roleConditional}
      to={to}
      text={text}
      activeRoute={"next-step"}
    />
  );
};

const LoginLogout = (user, loginUrl, logoutUrl) => {
  return (
    <Nav.Item className="d-flex align-items-center">
      {user.email ? (
        <Tippy content={user.email}>
          <Button
            className="ms-1 signInOut"
            variant="light"
            href={logoutUrl}
            aria-label="logout"
          >
            Logga ut
          </Button>
        </Tippy>
      ) : (
        <Button
          className="ms-1 signInOut"
          variant="light"
          href={loginUrl}
          aria-label="login"
        >
          Logga in
        </Button>
      )}
    </Nav.Item>
  );
};

export default {
  DigitalizationGroup,
  CurrentSituation,
  Radar,
  Comparison,
  NextStep,
  LoginLogout,
};
