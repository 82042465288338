import React, { PureComponent } from "react";
import { Link } from "react-router-dom";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import TextWithLinks from "components/HTMLText";
import useText from "services/useText";
import { text } from "@fortawesome/fontawesome-svg-core";
import "./style.scss";

const NeedsAbilitiesChart = ({ abilities }) => {
  const height = 54 + abilities.rows.length * (30 + 10);
  const colors = [
    "#1598AA",
    "#AA5A9F",
    "#B48128",
    "#2A6A95",
    "#139A7E",
    "#231F20",
    "#78818C",
    "#AEB3B6",
    "#40C7D6",
    "#CA94C2",
    "#D7B754",
    "#6C9BB7",
    "#70CFC1",
  ];
  const navigate = useNavigate();
  const text = useText();

  const handleClick = (data, index) => {
    navigate(
      "details/" +
        data.payload.group +
        "/" +
        encodeURIComponent(data.payload.id)
    );
  };

  return (
    <div className="abilitiesChart">
      <div className="titles d-flex">
        <div className="ability-title-wrapper">
          <div className="ability-title">{abilities.title}</div>
        </div>
        <div className="myStateTitle">Mitt bolag</div>
        <Tippy content={text.JÄMFÖR_BEHOV.SAMVERKAN_TOOLTIP}>
          <div className="myCooperation">Möjlig samverkan</div>
        </Tippy>
      </div>
      <div className="d-flex">
        <ResponsiveContainer width="80%" height={height}>
          <BarChart
            data={abilities.rows}
            layout="vertical"
            barCategoryGap={5}
            margin={{ top: 0, right: 30, left: 0, bottom: 0 }}
          >
            <XAxis type="number" tick={{ fontSize: 12 }} />
            <YAxis
              type="category"
              width={250}
              padding={{ left: 20 }}
              dataKey="name"
              tick={{ fontSize: 16 }}
            />

            <Legend />
            <Tooltip
              wrapperStyle={{ fontSize: 16, fontWeight: 700 }}
              allowEscapeViewBox={{
                x: true,
                y: true,
              }}
            />
            <Bar
              name="Inte prioriterat"
              dataKey="aNoPriority"
              stackId="a"
              fill={colors[0]}
              cursor="pointer"
              onClick={handleClick}
            />
            <Bar
              name="Ej påbörjat"
              dataKey="aNotStarted"
              stackId="a"
              fill={colors[1]}
              cursor="pointer"
              onClick={handleClick}
            />
            <Bar
              name="Påbörjad"
              dataKey="aStarted"
              stackId="a"
              fill={colors[2]}
              cursor="pointer"
              onClick={handleClick}
            />
            <Bar
              name="Använder, ej nöjd"
              dataKey="aUsingImprovements"
              stackId="a"
              fill={colors[3]}
              cursor="pointer"
              onClick={handleClick}
            />
            <Bar
              name="Använder nöjd"
              dataKey="aUsingAsIntended"
              stackId="a"
              fill={colors[4]}
              cursor="pointer"
              onClick={handleClick}
            />
          </BarChart>
        </ResponsiveContainer>

        <div className="ownState">
          {abilities.rows.map((ability, i) => (
            <div className="d-flex" key={i}>
              <div className={"state " + ability.ownState}></div>
              {ability.coops > 0 && (
                <>
                  <Link to={"coops/" + ability.group + "/" + ability.id}>
                    <img src="/co-op.png" />
                  </Link>
                  <span>{ability.coops}</span>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default NeedsAbilitiesChart;
