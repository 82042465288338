import { Link } from "react-router-dom";
import { Col, Container, Row, Button } from "react-bootstrap";
import Video from "components/Video";
import useText from "../../services/useText";
import useUser from "../../services/useUser";
import Steps from "./components/Steps";

import "./style.scss";

const Home = () => {
  const user = useUser();
  const text = useText();

  return (
    user && (
      <div className="home-page">
        <Container className="top-container">
          <Row className="justify-content-center">
            <Col className="top-title" md={8}>
              <h1>{text.HOME.TITLE}</h1>
              <p className="pb-2">{text.HOME.INTRO}</p>
              <Link
                to={
                  "./organizations/" +
                  user.organizationId +
                  "/current-survey/introduction"
                }
              >
                <Button variant="action" className="mb-4">
                  {text.HOME.PÅBÖRJA_KARTLÄGGNING}
                </Button>
              </Link>
            </Col>
          </Row>
        </Container>

        <Container fluid className="lower-container">
          <Container>
            <Row className="steps mb-4 justify-content-center">
              <Col md={10}>
                <Steps />
              </Col>
            </Row>
            <Row className="justify-content-center pt-5 pb-5">
              <Col md={5}>
                <h2>{text.HOME.VIDEO_TITLE}</h2>
                <p>{text.HOME.VIDEO_INTRO}</p>
                <Link to={"/about"}>
                  <Button className="mt-2" variant="hollow">
                    {text.HOME.LÄS_MER}
                  </Button>
                </Link>
              </Col>
              <Col md={5}>
                <Video />
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    )
  );
};

export default Home;
