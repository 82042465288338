import { useRef, useEffect } from "react";
import * as d3 from "d3";

export default ({ d3Func, data }) => {
  const ref = useRef();
  useEffect(() => {
    const svgElement = d3.select(ref.current);
    d3Func(svgElement, data);
  }, [data]);
  return <svg width="1200" height="900" ref={ref} />;
};
