import BackButton from "components/BackButton";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import useStatDetails from "services/useStatDetails";
import useSurvey from "services/useSurvey";
import "./style.scss";

const StatsDetailPage = () => {
  const { organizationId, qid } = useParams();
  const survey = useSurvey();
  const statsDetails = useStatDetails(organizationId, qid);
  const [questionMap, setQuestionMap] = useState(null);

  useEffect(() => {
    const p = survey.pages.reduce((memo, page) => {
      page.elements &&
        page.elements.forEach((element) => {
          memo[element.name] = element;
        });
      return memo;
    }, {});
    setQuestionMap(p);
  }, [statsDetails]);

  return (
    questionMap &&
    statsDetails && (
      <div className="container StatsDetailPage">
        <div className="row">
          <div className="col">
            <BackButton />
            <h2>{questionMap[qid].title.sv}</h2>

            {statsDetails.map((detail) => (
              <div className="state" key={detail.option}>
                <h3>{detail.option === "other" ? "Annat" : detail.option}</h3>
                {detail.organizations.map((org) => (
                  <Link key={org.name} to={"../organization-info/" + org.oid}>
                    {org.name +
                      (detail.option === "other" ? " - " + org.value : "")}
                  </Link>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  );
};

export default StatsDetailPage;
