import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useSurvey from "services/useSurvey";
import useStats from "services/useStats";
import useText from "services/useText";
import { Link } from "react-router-dom";
import "./style.scss";
import { Container, Row } from "react-bootstrap";
import { Col } from "react-bootstrap";
import BackButton from "components/BackButton";

const NeedAbilitiesDetailPage = () => {
  const { organizationId, group, id } = useParams();
  const stats = useStats(organizationId);
  const survey = useSurvey();
  const text = useText();
  const [states, setStates] = useState(null);

  const getNeedTitle = (id) => {
    const improvementsPage = survey.pages.find(
      (p) => p.name === "Strategiska förbättringsområden"
    );
    const panels = improvementsPage.elements.filter((e) => e.type === "panel");
    const matrixes = panels.map((p) => {
      return p.elements.find((e) => e.type === "matrix");
    });
    let title = "";
    matrixes.forEach((matrix) => {
      matrix.rows.forEach((row) => {
        if (id === row.value) {
          title = row.text.sv;
        }
      });
    });
    return title;
  };

  useEffect(() => {
    let newStates = {
      aNoPriority: {
        title: "Ej prioriterat",
        organizations: [],
      },
      aNotStarted: { title: "Ej startat", organizations: [] },
      aStarted: { title: "Påbörjat", organizations: [] },
      aUsingImprovements: {
        title: "Använder ej tillfredsställande",
        organizations: [],
      },
      aUsingAsIntended: {
        title: "Använder tillfredsställande",
        organizations: [],
      },
    };
    if (stats) {
      stats.forEach((stat) => {
        if (stat[group] && stat[group][id] && newStates[stat[group][id]]) {
          newStates[stat[group][id]].organizations.push({
            id: stat.oid,
            name: stat.oname,
          });
        }
      });
    }
    setStates(newStates);
  }, [stats]);

  return (
    states && (
      <Container className="businessNeedsDetailPage">
        <Row>
          <Col>
            <BackButton />
            <h2>{getNeedTitle(id)}</h2>
            <p>{text.BEHOV_DETALJER.INTRO}</p>
            {Object.values(states).map((state) => (
              <div className="state">
                <h3>{state.title}</h3>
                {state.organizations.map((org) => (
                  <Link key={org.name} to={"../organization-info/" + org.id}>
                    {org.name}
                  </Link>
                ))}
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    )
  );
};

export default NeedAbilitiesDetailPage;
