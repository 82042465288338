import http from "utils/http";
import { useState, useEffect } from "react";

function useAbilitiesSummary(organizationId, questionId) {
  const [summary, setSummary] = useState(null);

  useEffect(() => {
    getAbilitesSummary(organizationId, questionId);
  }, []);

  async function getAbilitesSummary(organizationId, questionId) {
    const response = await http.get(
      "/api/organizations/" + organizationId + "/responses/abilitiesSummary/"
    );
    setSummary(response.data);
  }

  return summary;
}

export default useAbilitiesSummary;
