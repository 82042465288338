import { useParams } from "react-router-dom";
import StackedBarChart from "components/StackedBarChart";
import { Container, Row } from "react-bootstrap";
import useText from "services/useText";
import useAbilitiesSummary from "services/useAbilitiesSummary";

export default () => {
  const { organizationId } = useParams();
  const text = useText();
  const groups = useAbilitiesSummary(organizationId);

  return (
    groups && (
      <Container className="ablities-page">
        <Row>
          <h1>{text.JÄMFÖR_FÖRMÅGOR.TITLE}</h1>
          <p className="pb-2">{text.JÄMFÖR_FÖRMÅGOR.INTRO}</p>
          <div className="d-flex justify-content-center mb-2"></div>
        </Row>
        <Row>
          <div className="col-12">
            {groups &&
              groups.map((group) => (
                <StackedBarChart key={group.title} abilities={group} />
              ))}
          </div>
        </Row>
      </Container>
    )
  );
};
