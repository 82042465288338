import http from "utils/http";
import { useState, useEffect } from "react";

function useStatDetails(organizationId, questionId) {
  const [statDetails, setStatDetails] = useState(null);

  useEffect(() => {
    getStatsDetails(organizationId, questionId);
  }, []);

  async function getStatsDetails(organizationId, questionId) {
    const response = await http.get(
      "/api/organizations/" +
        organizationId +
        "/responses/details/" +
        questionId
    );
    setStatDetails(response.data);
  }

  return statDetails;
}

export default useStatDetails;
