import React, { useState } from "react";
import {
  Radar,
  RadarChart,
  PolarGrid,
  PolarAngleAxis,
  PolarRadiusAxis,
  Legend,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import HTMLText from "components/HTMLText";
import useText from "../../services/useText";
import "./style.scss";

export default ({ data }) => {
  const text = useText();
  data.dimensions.forEach((d) => {
    d.fullmark = 1;
  });
  const getAverage = (payload) => {
    if (payload[1] && payload[1].value) {
      return Math.round(payload[1].value * 100) + "%";
    }
    return "-";
  };

  const CustomTooltip = ({ active, payload, label }) => {
    console.log(label);
    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          <p className="label">{`${label} : ${Math.round(
            payload[0].value * 100
          )}% (Medel ${getAverage(payload)})`}</p>
          {label === "Kompetens" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_KOMPETENS}
            </HTMLText>
          )}
          {label === "Teknisk plattform" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_TEKNISK_PLATTFORM}
            </HTMLText>
          )}
          {label === "Upphandling" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_UPPHANDLING}
            </HTMLText>
          )}
          {label === "Datadriven verksamhet" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_DATADRIVEN}
            </HTMLText>
          )}
          {label === "Informationssäkerhet" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_INFORMATIONSSÄKERHET}
            </HTMLText>
          )}
          {label === "Integrations- och API-hantering" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_INTEGRATIONSHANTERING}
            </HTMLText>
          )}
          {label === "Innovation och verksamhetsutveckling" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_INNOVATION_VERKSAMHETSUTVECKLING}
            </HTMLText>
          )}
          {label === "Organisation och arbetssätt" && (
            <HTMLText className="desc">
              {text.RADAR.INDIKATOR_ORGANISATION_ARBETSSÄTT}
            </HTMLText>
          )}
        </div>
      );
    }

    return null;
  };

  let [posData, setPosData] = useState({});
  const getTooltipPos = () => {
    let x = 900;
    let y = 600;
    if (posData.x > 648) {
      x = 0;
    }
    if (posData.y > 400) {
      y = 0;
    }
    return { x, y };
  };

  return (
    <ResponsiveContainer width="100%" aspect="1.6" className="polarChart">
      <RadarChart
        cx="50%"
        cy="50%"
        outerRadius="90%"
        data={data.dimensions}
        onMouseMove={(e) => setPosData({ x: e.chartX, y: e.chartY })}
      >
        <PolarGrid wrapperStyle={{ fontSize: 14 }} />
        <PolarAngleAxis dataKey="subject" />
        <Tooltip
          allowEscapeViewBox={{
            x: true,
            y: true,
          }}
          isAnimationActive={true}
          position={getTooltipPos()}
          content={<CustomTooltip />}
        />
        <PolarRadiusAxis domain={[0, 1]} tick={false} />
        <Radar
          name="Mitt bolag"
          dataKey="score"
          stroke="#ea9999"
          fill="#ea9999"
          fillOpacity={0.4}
        />
        <Radar
          name="Medel"
          dataKey="avg"
          stroke="#9fc5e8"
          fill="#9fc5e8"
          fillOpacity={0.4}
        />
        <Legend verticalAlign="top" align="left" />
      </RadarChart>
    </ResponsiveContainer>
  );
};
