import React, { useEffect } from "react";
import "./style.scss";

export default () => {
  var media;
  var mediaOverlay;
  var timerWrapper;
  var timerBar;

  function playPauseMedia() {
    if (media.paused) {
      media.setAttribute("data-icon", "u");
      media.classList.add("playing");
      mediaOverlay.classList.add("hidden");
      media.play();
    } else {
      media.setAttribute("data-icon", "P");
      mediaOverlay.classList.remove("hidden");
      media.classList.remove("playing");
      media.pause();
    }
  }

  function PlayMedia() {
    media.setAttribute("data-icon", "u");
    mediaOverlay.classList.add("hidden");
    media.classList.add("playing");
    media.play();
  }

  function progressBar() {
    var barLength =
      timerWrapper.clientWidth * (media.currentTime / media.duration);
    timerBar.style.width = barLength + "px";
  }

  function endedMedia() {
    media.load();
  }

  useEffect(() => {
    media = document.querySelector("video");
    mediaOverlay = document.getElementById("media-overlay");
    timerWrapper = document.querySelector(".timer");
    timerBar = document.querySelector(".timer div");

    media.removeAttribute("controls");
    media.addEventListener("click", playPauseMedia);
    media.addEventListener("ended", endedMedia);
    media.addEventListener("timeupdate", progressBar);
    timerBar.addEventListener("click", playPauseMedia);
    mediaOverlay.addEventListener("click", PlayMedia);
  }, []);

  return (
    <div className="video">
      <video
        controls
        id="rr-video"
        spreload="metadata"
        poster="/intro-poster.png"
      >
        <source src="/digitaliseringsradarn.mp4" type="video/mp4"></source>
        Sorry, your browser doesn't support embedded videos, but don't worry,
        you can <a href="/video/roughly_right_how_too.mp4">download it</a> and
        watch it with your favorite video player!
      </video>
      <div className="timer">
        <div></div>
      </div>
      <div id="media-overlay">
        <div className="play-button-wrapper">
          <div className="play-button">
            <span>{"Spela"}</span>
            <span className="triangle"></span>
          </div>
        </div>
      </div>
    </div>
  );
};
