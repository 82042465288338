import StackedBarChart from "components/StackedBarChart";
import { useParams } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import useText from "services/useText";
import useNeedsSummary from "services/useNeedsSummary";

export default () => {
  const { organizationId } = useParams();
  const text = useText();
  const groups = useNeedsSummary(organizationId);

  return (
    groups && (
      <Container className="abilities">
        <Row>
          <h1>{text.JÄMFÖR_BEHOV.TITLE}</h1>
          <p className="pb-2">{text.JÄMFÖR_BEHOV.INTRO}</p>
        </Row>
        <Row>
          <div className="col-12 mb-4">
            {groups &&
              groups.map((group) => (
                <StackedBarChart key={group.title} abilities={group} />
              ))}
          </div>
        </Row>
      </Container>
    )
  );
};
