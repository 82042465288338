import { LinkContainer } from "react-router-bootstrap";
import { Nav } from "react-bootstrap";
import useText from "services/useText";
import { useLocation } from "react-router-dom";

import "./styles.scss";

const CompareNav = () => {
  const text = useText();
  const location = useLocation();

  const activeClass = (path) => {
    return location.pathname.indexOf(path) !== -1 ? " active" : " inactive";
  };

  return (
    <Nav className="mt-4 border-bottom" role="navigation">
      <Nav.Item className="compare-nav">
        <LinkContainer to={"it-prerequisites"}>
          <Nav.Link
            eventKey="it-prerequisites"
            className={activeClass("it-prerequisites")}
          >
            {text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.TITLE}
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item className="compare-nav">
        <LinkContainer to={"business-needs"}>
          <Nav.Link
            eventKey="business-needs"
            className={activeClass("business-needs")}
          >
            {text.JÄMFÖR_BEHOV.TITLE}
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item className="compare-nav">
        <LinkContainer to={"abilities"}>
          <Nav.Link eventKey="abilities" className={activeClass("abilities")}>
            {text.JÄMFÖR_FÖRMÅGOR.TITLE}
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
      <Nav.Item className="compare-nav">
        <LinkContainer to={"it-additional"}>
          <Nav.Link
            eventKey="abilities"
            className={activeClass("it-additional")}
          >
            {text.JÄMFÖR_IT_ÖVRIGT.TITLE}
          </Nav.Link>
        </LinkContainer>
      </Nav.Item>
    </Nav>
  );
};

export default CompareNav;
