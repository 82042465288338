import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import * as d3 from "d3";
import * as crossfilter from "crossfilter";
import * as dc from "dc";
import useSurvey from "services/useSurvey";
import useText from "services/useText";
import "../../../../node_modules/dc/dist/style/dc.css";
import "./styles.scss";
import { Row, Col } from "react-bootstrap";
import Tippy from "@tippyjs/react";

const baseQuestions = [
  {
    id: "qRealEstateSystem",
    chart: "pie",
  },
  {
    id: "qFastAPI",
    chart: "pie",
  },
  {
    id: "qInfrastructure",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qExternalWeb",
    chart: "pie",
  },
  {
    id: "qOperatingSystems",
    chart: "pie",
  },
  {
    id: "qContainers",
    chart: "pie",
  },
  {
    id: "qInfrastructureSatisfaction",
    chart: "bar",
    domain: [1, 2, 3, 4, 5],
  },
  {
    id: "qITMaintenance",
    chart: "pie",
  },
  {
    id: "qITRoles",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qDevelopmentWoW",
    chart: "pie",
  },
  {
    id: "qAddingNewServices",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qSecurityCompetence",
    chart: "bar",
    domain: [1, 2, 3, 4, 5],
  },
  {
    id: "qIntegrationPlatforms",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qDigitizing",
    chart: "pie",
  },
];

const additionalQuestions = [
  {
    id: "qProcurement",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qDataDrivenDecisions",
    chart: "pie",
  },
  {
    id: "qInnovationStrategy",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qInnovationStrategyResources",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qBizDev",
    chart: "pie",
    multipleValues: true,
  },
  {
    id: "qProjectManagement",
    chart: "pie",
  },
  {
    id: "qCooperation",
    chart: "pie",
  },
  {
    id: "qCooperationTypes",
    chart: "pie",
  },
  // {
  //   id: "qFastAPIVersion",
  //   chart: "pie",
  // },
];

export default ({ stats, organizationId, questions }) => {
  const survey = useSurvey();
  const text = useText();

  const ownAnswers = stats.find((s) => s.oid === organizationId);

  const reset = () => {
    dc.filterAll();
    dc.renderAll();
  };

  useEffect(() => {
    // Loop through and mark missing info
    stats.forEach((stat) => {
      setOfQuestions().forEach((question) => {
        if (!question.multipleValues) {
          if (stat[question.id] === "other") {
            stat[question.id] = "Annat";
          }
          if (stat[question.id] === "none") {
            stat[question.id] = "Inget";
          }
          stat[question.id] = stat[question.id] || "Saknas";
        } else {
          if (stat[question.id]) {
            let index = stat[question.id].indexOf("other");
            if (index !== -1) {
              stat[question.id][index] = "Annat";
            }
            index = stat[question.id].indexOf("none");
            if (index !== -1) {
              stat[question.id][index] = "Inget";
            }
          }
        }
      });
    });
  }, [questions, stats]);

  const colors = [
    "#1598AA",
    "#AA5A9F",
    "#B48128",
    "#2A6A95",
    "#139A7E",
    "#231F20",
    "#78818C",
    "#AEB3B6",
    "#40C7D6",
    "#CA94C2",
    "#D7B754",
    "#6C9BB7",
    "#70CFC1",
  ];

  const setOfQuestions = () => {
    return questions !== "base" ? additionalQuestions : baseQuestions;
  };

  const questionMap = survey.pages.reduce((memo, page) => {
    page.elements &&
      page.elements.forEach((element) => {
        memo[element.name] = element;
      });
    return memo;
  }, {});

  const addPieMultipleValues = (id, attr, crossfilter) => {
    const pieChart = dc.pieChart(id);
    const dim = crossfilter.dimension((data) => {
      return data[attr] || [];
    });
    const group = dim.group().reduce(
      (p, v) => {
        if (v[attr] && v[attr].length) {
          v[attr].forEach((a) => {
            if (!p[a]) {
              p[a] = 0;
            }
            p[a]++;
          });
        }
        return p;
      },
      (p, v) => {
        Object.keys(p).forEach((k) => (p[k] = 0));
        return p;
      },
      () => {
        return {};
      }
    );
    pieChart
      .width(550)
      .height(250)
      .slicesCap(5)
      .innerRadius(60)
      .cx(140)
      .dimension(dim)
      .group(group)
      .ordinalColors(colors)
      .data((group) => {
        const data = group.all();
        const hash = data.reduce((memo, d) => {
          Object.entries(d.value).forEach(([key, value]) => {
            if (!memo[key]) {
              memo[key] = value;
            } else {
              memo[key] += value;
            }
          });
          return memo;
        }, {});
        return Object.entries(hash).map(([key, value]) => ({
          key: key,
          value: value,
        }));
      })
      // .legend(dc.legend().x(275).y(20).gap(4).highlightSelected(true))
      .legend(
        dc
          .htmlLegend()
          .container("#legend-" + attr)
          .horizontal(false)
          .highlightSelected(true)
          .legendText((d) => {
            console.log(ownAnswers, attr);
            return (
              d.name +
              (ownAnswers[attr]
                ? ownAnswers[attr].some((a) => a === d.name)
                  ? " ◀"
                  : ""
                : "")
            );
          })
      )
      .on("pretransition", (chart) => {
        // Fix for weird y positioning of the legend lebel text
        chart.selectAll(".dc-legend-item text").attr("y", 10);
        chart.selectAll("text.pie-slice").text(function (d) {
          if (d.endAngle - d.startAngle > 0.15) {
            return (
              dc.utils.printSingleValue(
                (((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100).toFixed(0)
              ) + "%"
            );
          } else {
            return "";
          }
        });
      });
  };

  const addPie = (id, attr, crossfilter) => {
    const pieChart = dc.pieChart(id);
    const dim = crossfilter.dimension((data) => {
      return data[attr];
    });
    const group = dim.group().reduceCount();

    const filteredGroup = removeEmptyBins(group);

    pieChart
      .width(550)
      .height(250)
      .slicesCap(5)
      .innerRadius(60)
      .cx(140)
      .dimension(dim)
      .group(filteredGroup)
      .ordinalColors(colors)
      .legend(
        dc
          .htmlLegend()
          .container("#legend-" + attr)
          .horizontal(false)
          .highlightSelected(true)
          .legendText((d) => {
            return d.name + (d.name === ownAnswers[attr] ? " ◀" : "");
          })
      )
      //.legend(dc.legend().x(275).y(20).gap(4).highlightSelected(true))
      .on("pretransition", (chart) => {
        // Fix for weird y positioning of the legend lebel text
        chart.selectAll(".dc-legend-item text").attr("y", 10);
        chart.selectAll("text.pie-slice").text(function (d) {
          if (d.endAngle - d.startAngle > 0.15) {
            return (
              dc.utils.printSingleValue(
                (((d.endAngle - d.startAngle) / (2 * Math.PI)) * 100).toFixed(0)
              ) + "%"
            );
          } else {
            return "";
          }
        });
      });
  };

  const removeEmptyBins = function (source_group) {
    return {
      all: function () {
        return source_group.all().filter(function (d) {
          return d.key !== "Saknas";
        });
      },
    };
  };

  const addRowBar = (id, attr, crossfilter) => {
    const chart = new dc.RowChart(id);

    // Fixing bootstrap clash with .orw > * { widht: 00% }. See scss for .row-fix css
    chart._rowCssClass = "row-fix";

    const dim = crossfilter.dimension((data) => {
      return data[attr];
    });
    const group = dim.group().reduceCount();

    chart
      .width(550)
      .height(250)
      .dimension(dim)
      .group(group)
      .ordinalColors(colors)
      .elasticX(true)
      .xAxis()
      .ticks(4);
  };

  const addBar = (id, attr, crossfilter, domain) => {
    const chart = new dc.BarChart(id);
    const dim = crossfilter.dimension((data) => {
      return data[attr];
    });
    const group = dim.group().reduceCount();
    chart
      .width(400)
      .height(280)
      .x(d3.scaleBand().domain(domain || [1, 2, 3, 4, 5]))
      .xUnits(dc.units.ordinal)
      .ordinalColors(colors)
      .brushOn(false)
      .dimension(dim)
      .group(group)
      .xAxis()
      .tickFormat(function (v) {
        return v + (v === ownAnswers[attr] ? " ◀" : "");
      });
  };

  const addCount = (id, attr, crossfilter) => {
    const numberDisplay = new dc.NumberDisplay(id);
    const dim = crossfilter.dimension((data) => {
      return data[attr];
    });
    const group = dim.group().reduceCount();
    numberDisplay
      .formatNumber(d3.format(".0f"))
      .valueAccessor((d) => {
        return d.value;
      })
      .group(
        crossfilter.groupAll().reduce(
          (p, d) => {
            if (d[attr] !== "Saknas" && d[attr] !== undefined) {
              p.value++;
            }
            return p;
          },
          (p, d) => {
            if (d[attr] !== "Saknas" && d[attr] !== undefined) {
              p.value--;
            }
            return p;
          },
          () => ({ value: 0 })
        )
      );
  };

  useEffect(() => {
    const cf = crossfilter(stats);
    setOfQuestions().forEach((question) => {
      if (question.chart === "pie") {
        if (question.multipleValues) {
          addPieMultipleValues("#" + question.id, question.id, cf);
        } else {
          addPie("#" + question.id, question.id, cf);
        }
      } else if (question.chart === "row") {
        addRowBar("#" + question.id, question.id, cf);
      } else {
        addBar("#" + question.id, question.id, cf, question.domain);
      }
      addCount("#count-" + question.id, question.id, cf);
    });

    dc.renderAll();
  }, []);

  return (
    <div className="testing">
      <Row className="mb-5">
        {setOfQuestions().map(
          (question) =>
            questionMap[question.id] && (
              <Col md={6} key={question.id} className="gauge-card">
                <div className="gauge-card-content">
                  <div className="title">
                    <h3>{questionMap[question.id].title.sv}</h3>
                  </div>
                  <div className="d-flex">
                    <div
                      style={{ width: 280, height: 280 }}
                      id={question.id}
                    ></div>
                    <div
                      style={{ width: 280, height: 280 }}
                      id={"legend-" + question.id}
                    ></div>
                  </div>

                  <div className="reset-row d-flex align-items-baseline">
                    <Tippy
                      content={text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.ANTAL_SVAR_INFO}
                    >
                      <span id={"count-" + question.id}></span>
                    </Tippy>
                    <Tippy content={text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.RESET_INFO}>
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          reset();
                        }}
                      >
                        {text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.RESET}
                      </a>
                    </Tippy>
                    <Tippy
                      content={text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.DETALJER_INFO}
                    >
                      <Link to={"it-details/" + question.id}>
                        {text.JÄMFÖR_IT_FÖRUTSÄTTNINGAR.DETALJER}
                      </Link>
                    </Tippy>
                  </div>
                </div>
              </Col>
            )
        )}
      </Row>
    </div>
  );
};
