import { Button, Col, Container, Row } from "react-bootstrap";
import useText from "services/useText";
import HTMLText from "components/HTMLText";
import "./style.scss";

const Login = () => {
  const loginUrl =
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001/api/login"
      : "/api/login";
  const text = useText();

  return (
    <Container className="login">
      <Row>
        <Col className="d-flex justify-content-center mb-5">
          <img src="/black-logo.png"></img>
        </Col>
      </Row>
      <Row className="justify-content-center">
        <Col md={8} className="content-area">
          <h1>{text.LOGIN.TITLE}</h1>
          <HTMLText>{text.LOGIN.INTRO}</HTMLText>

          <div className="mb-4">
            <Button variant="action" href={loginUrl}>
              {text.LOGIN.LOGIN_KNAPP}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
