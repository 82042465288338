import { useState, useEffect } from "react";
import useRadars from "services/useRadar";
import RadarChart from "components/RadarChart";
import PolarChart from "components/PolarChart";
import { useParams } from "react-router-dom";
import useIndicators from "services/useIndicators";
import useOrganizationResponse from "services/useOrganizationResponse";
import { Col, Container, Row } from "react-bootstrap";
import useText from "services/useText";
import Tippy from "@tippyjs/react";
import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";
import Feedback from "components/Feedback";
import "./style.scss";
import useOrganization from "services/useOrganization";

const RadarPage = () => {
  const { organizationId } = useParams();
  const text = useText();
  const indictorTitleMap = {
    procurement: text.RADAR.INDIKATOR_LABEL_procurement,
    competence: text.RADAR.INDIKATOR_LABEL_competence,
    informationSecurity: text.RADAR.INDIKATOR_LABEL_informationSecurity,
    innovationBusinessDevelopment:
      text.RADAR.INDIKATOR_LABEL_innovationBusinessDevelopment,
    integrationHandling: text.RADAR.INDIKATOR_LABEL_integrationHandling,
    datadriven: text.RADAR.INDIKATOR_LABEL_datadriven,
    organizationWayOfWorking:
      text.RADAR.INDIKATOR_LABEL_organizationWayOfWorking,
    technicalPlatform: text.RADAR.INDIKATOR_LABEL_technicalPlatform,
  };
  const [radars] = useRadars(organizationId);
  const [currentRadarIndex, setCurrentRadarIndex] = useState(0);
  const [currentSizeIndex, setCurrentSizeIndex] = useState(0);
  const [indicators, getIndicator] = useIndicators(
    organizationId,
    indictorTitleMap
  );
  const survey = useOrganizationResponse(organizationId, organizationId);
  const org = useOrganization(organizationId);

  const sizeOptions = [
    { title: "Jämför med alla bolag", value: "0-10000000" },
    { title: "0-499 lägenheter", value: "0-499" },
    { title: "500-999 lägenheter", value: "500-999" },
    { title: "1000-1999 lägenheter", value: "1000-1999" },
    { title: "2000-4999 lägenheter", value: "2000-4999" },
    { title: "5000-9999 lägenheter", value: "5000-9999" },
    { title: "> 10000 lägenheter", value: "10000-10000000" },
  ];

  useEffect(() => {
    const [from, to] = sizeOptions[currentSizeIndex].value.split("-");
    getIndicator(from, to, currentRadarIndex);
  }, [currentSizeIndex, currentRadarIndex]);

  useEffect(() => {
    if (radars.length > 0 && radars[0].layers.strategic.length === 0) {
      alert("Du måste fylla i ditt nuläga innan du kan få en radarbild");
    }
  }, [radars]);

  return (
    <div className="RadarPage">
      {radars.length === 0 && (
        <Container>
          <Row className="justify-content-center">
            <Col md={10}>
              <p className="mt-5">
                Ni måste först påbörja kartläggningen innan ni kan få ett
                resultat.
              </p>
              <Link to={"./../current-survey/introduction"}>
                <Button variant="action">Gå till kartläggningen</Button>
              </Link>
            </Col>
          </Row>
        </Container>
      )}
      {radars.length > 0 && (
        <>
          <Container>
            <Row className="justify-content-center">
              <Col md={10}>
                <h2>Feedback från kartläggning</h2>
                {indicators && indicators.dimensions.length > 0 && (
                  <Feedback
                    radar={radars[0]}
                    survey={survey}
                    organization={org}
                    indicators={indicators}
                  />
                )}
              </Col>
            </Row>
            <Row className="justify-content-center">
              <Col md={10}>
                <h2>
                  {text.RADAR.RADAR_TITLE} {survey && survey.response.oname}
                </h2>
                <p className="mb-0">{text.RADAR.RADAR_INTRO}</p>
                <div className="d-flex justify-content-end">
                  <Tippy content={text.RADAR.RADAR_INFO}>
                    <img className="info-icon" src="/info-large.svg" />
                  </Tippy>
                </div>
              </Col>
            </Row>
          </Container>
          <Container fluid>
            <Row>
              <Col className="d-flex justify-content-center">
                <RadarChart radarData={radars[currentRadarIndex]} />
              </Col>
            </Row>
          </Container>
          <Container>
            <div className="row">
              <div className="col-4 offset-4">
                <select
                  className="form-select"
                  aria-label="Välj tidigare version av digitaliseringradarn"
                  onChange={(e) => setCurrentRadarIndex(e.target.value)}
                >
                  {radars.map((radar, i) => (
                    <option value={i} key={i}>
                      {i === 0
                        ? "Senaste version: " + radar.date.substring(0, 10)
                        : radar.date.substring(0, 10)}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <Row className="justify-content-center">
              <Col md={10}>
                <h2>
                  {text.RADAR.INDIKATOR_TITLE} {survey && survey.response.oname}
                </h2>
                <p className="mb-0">{text.RADAR.INDIKATOR_INTRO}</p>
                <div className="d-flex justify-content-end">
                  <Tippy content={text.RADAR.INDIKATOR_INFO}>
                    <img className="info-icon" src="/info-large.svg" />
                  </Tippy>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                {indicators && indicators.dimensions.length && (
                  <PolarChart data={indicators} />
                )}
              </Col>
            </Row>
            <Row>
              <div className="col-4 offset-4">
                <select
                  className="form-select"
                  aria-label="Jäömför med alla bostadbolag"
                  onChange={(e) => setCurrentSizeIndex(e.target.value)}
                  // onChange={(e) => companySizeChanged(e.target.value)}
                >
                  {sizeOptions.map((size, i) => (
                    <option key={size.value} value={i}>
                      {size.title}
                    </option>
                  ))}
                </select>
              </div>
            </Row>
            <Row className="justify-content-center pb-5">
              <Col md={10}>
                <Link to={"./../compare/it-prerequisites"}>
                  <Button variant="action">
                    {text.RADAR.GÅ_TILL_JÄMFÖRBOLAG}
                  </Button>
                </Link>
              </Col>
            </Row>
          </Container>
        </>
      )}
    </div>
  );
};

export default RadarPage;
