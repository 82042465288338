import { Col, Container, Row, Button } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import useText from "services/useText";
import HTMLText from "components/HTMLText";
import "./style.scss";
import useOrganization from "services/useOrganization";
import useUser from "services/useUser";
import useCreateResponseVersion from "services/useCreateReponseVersion";

export default () => {
  const user = useUser();
  const params = useParams();
  const navigate = useNavigate();
  const [org] = useOrganization(params.organizationId);
  const text = useText();
  const { createVersion } = useCreateResponseVersion(params.organizationId);

  const getStartButtonText = () => {
    if (org && org.surveys && org.surveys.length > 0) {
      return text.ENKÄT_INTRODUKTION.STARTA_SENASTE;
    } else {
      return text.ENKÄT_INTRODUKTION.STARTA;
    }
  };

  const clickCXreateNewVersion = async (evt) => {
    evt.preventDefault();
    if (window.confirm("Är du säker på att du vill skapa ny version?")) {
      await createVersion();
      navigate("..");
    }
  };

  return (
    <div className="survey-introduction route-page">
      <Container>
        <Row className="justify-content-center">
          <Col md={10}>
            <h1>{text.ENKÄT_INTRODUKTION.TITLE}</h1>
            <HTMLText>{text.ENKÄT_INTRODUKTION.INTRO}</HTMLText>
            <p className="small">{text.ENKÄT_INTRODUKTION.SMALL}</p>
            <Link to={".."}>
              <Button
                onClick={() => localStorage.setItem("shownIntro", true)}
                variant="action"
              >
                {org && org.surveys && org.surveys.length === 0
                  ? text.ENKÄT_INTRODUKTION.STARTA
                  : text.ENKÄT_INTRODUKTION.STARTA_SENASTE}
              </Button>
            </Link>
          </Col>
        </Row>
        <Row className="justify-content-center mt-4">
          <Col md={10}>
            {org &&
              org.surveys &&
              org.surveys.length > 0 &&
              user &&
              (user.role === "ORG_ADMIN" || user.role === "SYS_ADMIN") && (
                <>
                  <h2>{text.ENKÄT_INTRODUKTION.STARTA_NY_TITLE}</h2>
                  <p className="small">
                    {text.ENKÄT_INTRODUKTION.STARTA_NY_INTRO}
                  </p>
                  <Button
                    onClick={(evt) => clickCXreateNewVersion(evt)}
                    variant="hollow"
                  >
                    {text.ENKÄT_INTRODUKTION.STARTA_NY}
                  </Button>
                </>
              )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};
