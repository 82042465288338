import http from "utils/http";
import { useState, useEffect } from "react";

export default (organizationId, indictorTitleMap) => {
  const [indicator, setIndicator] = useState(null);

  useEffect(() => {
    getIndicator(0, 100000, 0);
  }, []);

  async function getIndicator(fromSize, toSize, responseIndex) {
    const response = await http.get(
      "/api/organizations/" +
        organizationId +
        "/indicators?fromsize=" +
        fromSize +
        "&tosize=" +
        toSize +
        "&responseIndex=" +
        responseIndex
    );
    setIndicator({
      dimensions: response.data.map((indicator) => {
        return {
          subject: indictorTitleMap[indicator.indicator],
          score: indicator.score,
          avg: indicator.avg,
        };
      }),
    });
  }

  return [indicator, getIndicator];
};
